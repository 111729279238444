import React from "react";
import golfer from "../assets/imgs/bg/golf-bg-1.png";
import Btn from "./Btn";
import { Row, Col } from "react-bootstrap";

export default function Feedback({ onClick }) {
  return (
    <>
      <div class="confirmation card">
        <Col className="d-flex justify-content-end">
          <Btn child="x" type="form" onClick={onClick} />
        </Col>
        <img src={golfer} class="confirmation-card-img-top" />
        <div class="card-body">
          <p class="confirmation-card-text">
            <h3 className="d-flex justify-content-center">
              Thank You For Your Inqury!
            </h3>
            <h6 className="d-flex justify-content-center">
              We will be in touch soon
            </h6>
          </p>
        </div>
      </div>
    </>
  );
}
