import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Membership from "./Membership";
import yes from "../assets/svgs/check-circle-fill-yes.svg";
import no from "../assets/svgs/check-circle-fill-no.svg";
import golfer from "../assets/imgs/bg/golf-gang.png";

export default function Memberships() {

  return (
    <div className="membership-parent" id="memberships">
      <Container>
        <Row>
          <div className="title">Memberships</div>
        </Row>
        <Row className="d-flex justify-content-center">
          <Membership/>
        </Row>
      </Container>
    </div>
  );
}
