import React from "react";
import golfer from "../assets/imgs/bg/golf-bg-1.png";
import Btn from "./Btn";
import { Row, Col } from "react-bootstrap";

export default function Confirmation({ date, time, onClick }) {
  return (
    <>
      <div class="confirmation card">
        <Col className="d-flex justify-content-end">
          <Btn child="x" type="form" onClick={onClick} />
        </Col>
        <img src={golfer} class="confirmation-card-img-top" />
        <div class="card-body">
          <p class="confirmation-card-text">
            <h3 className="d-flex justify-content-center">
              Your Tee Time is Booked!
            </h3>
            <Row>
              <Col lg={1} md={1} sm={1} xs={1}/>
              <Col>
                <h6 className="confirmation-subtitle">Date: {date}</h6>
                <h6 className="confirmation-subtitle">Time: {time}</h6>
              </Col>
              <Col lg={1} md={1} sm={1} xs={1}/>
            </Row>
          </p>
        </div>
      </div>
    </>
  );
}
