import React, { useState } from "react";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import Btn from "./Btn";
import { Container, Row, Col } from "react-bootstrap";
import Confirmation from "./Confirmation";
import ContactUs from "./ContactUs";

export default function DateCalendarValue() {
  const [dateValue, setDateValue] = useState(dayjs());
  const [timeValue, setTimeValue] = useState(
    dateValue.set("hour", 9).set("minute", 0)
  );
  const [confirmedDate, setConfirmedDate] = useState("");
  const [confirmedTime, setConfirmedTime] = useState("");
  const [showConfirmation, setShowConfirmation] = useState(false);

  // formatted dates
  const formattedDate = dateValue.format("MMMM DD, YYYY");
  const formattedTime = timeValue.format("h:mm A");

  function onSubmit() {
    setConfirmedDate(formattedDate);
    setConfirmedTime(formattedTime);
    setShowConfirmation(true);
  }

  function onClose() {
    setShowConfirmation(false);
  }

  const nineAM = dayjs().set("hour", 7).startOf("hour");
  const eightPm = dayjs().set("hour", 20).startOf("hour");

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <div className="tee-time" id="tee-time">
        <Container>
          <Row>
            <div className="title">Book a Tee Time</div>
          </Row>
          <Row style={{display:"flex", justifyContent:"space-around", margin: "10px"}}>
            <Col lg={4} md={6} sm={8} className="tee-time-parent">
              <div className="tee-time-holder">
                <Row className="tee-time-cal">
                  <DateCalendar
                    value={dateValue}
                    disablePast
                    onChange={(newValue) => setDateValue(newValue)}
                  />
                </Row>
                <Row className="tee-time-timep text-center">
                  <Col>
                    <TimePicker
                      defaultValue={nineAM}
                      value={timeValue}
                      minTime={nineAM}
                      maxTime={eightPm}
                      onChange={(newValue) => setTimeValue(newValue)}
                    />
                  </Col>
                </Row>
              </div>
              <Row className="tee-time-btn text-center">
                <Btn
                  child="Book Your Time"
                  type="standard"
                  onClick={() => onSubmit()}
                />
              </Row>
            </Col>
            <Col lg={5} md={6} sm={8} id="contact">
              <ContactUs/>
            </Col>
          </Row>
          {showConfirmation && (
            <div className="confirmation-overlay">
              <Confirmation
                date={confirmedDate}
                time={confirmedTime}
                onClick={() => onClose()}
              />
            </div>
          )}
        </Container>
      </div>
    </LocalizationProvider>
  );
}
