import React from "react";
import logo from "../assets/imgs/logo/fullLogo.png";

export default function Footer() {
  return (
    <div>
      <div className="footer">
          <div className="content">
            <div className="row-1">
              <img src={logo} style={{ height: "30px" }} />
            </div>
            <div className="row-2">
            </div>
          </div>
      </div>
    </div>
  );
}
