import React, { useState } from "react";
import Btn from "./Btn";
import { Container, Row } from "react-bootstrap";
import Feedback from "./Feedback";

export default function ContactUs() {
  const [showFeedback, setShowFeedback] = useState(false);

  return (
    <div className="contact-us">
      <Container>
        <Row>
          <div className="mini-title">Any More Questions?</div>
        </Row>
        <Row>
          <div class="mb-3">
            <label for="exampleFormControlInput1" class="form-label">
              Name
            </label>
            <input type="text" class="form-control" placeholder="John Smith" />
          </div>
          <div class="mb-3">
            <label for="exampleFormControlInput1" class="form-label">
              Email Address
            </label>
            <input
              type="email"
              class="form-control"
              placeholder="name@example.com"
            />
          </div>
          <div class="mb-3">
            <label for="exampleFormControlTextarea1" class="form-label">
              Comments
            </label>
            <textarea
              class="form-control"
              id="exampleFormControlTextarea1"
              rows="3"
            ></textarea>
          </div>
        </Row>
        <Row className="contact-us-btn">
          <Btn
            child="Submit"
            type="standard"
            onClick={() => setShowFeedback(true)}
          />
        </Row>
        {showFeedback ? (
          <div className="confirmation-overlay">
            <Feedback onClick={() => setShowFeedback(false)} />
          </div>
        ) : (
          ""
        )}
      </Container>
    </div>
  );
}
