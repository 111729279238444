import React, { useState, useEffect } from "react";
import { Navbar, Nav, Col, Row } from "react-bootstrap";
import logo from "../assets/imgs/logo/fullLogo.png";
import favicon from "../assets/imgs/logo/favicon.png";

export default function NavBar() {
  const [scrolled, setScrolled] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const onScroll = () => {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  useEffect(() => {
    const onMobile = () => {
      if (window.innerWidth < 760) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };
    onMobile();

    window.addEventListener("small", onMobile);

    return () => window.removeEventListener("small", onMobile);
  }, []);

  return (
    <Navbar expand="xxxl" className={isMobile ? "navbar small" : scrolled ? "navbar scrolled" : "navbar"}>
      {scrolled ? (
        <Navbar.Brand href="#home">
          <img src={favicon} />
        </Navbar.Brand>
      ) : (
        <>
          {isMobile ? (
            <Navbar.Brand href="#home">
              <img src={logo} style={{height: "25px"}}/>
            </Navbar.Brand>
          ) : (
            <Navbar.Brand href="#home">
              <img src={logo} />
            </Navbar.Brand>
          )}
        </>
      )}
      <Navbar.Toggle aria-controls="basic-navbar-nav">
        <span className="navbar-toggler-icon" href="#home"></span>
      </Navbar.Toggle>
      <Navbar.Collapse>
        <Row>
          <Col lg={10} md={8} sm={8}></Col>
          <Col lg={2} md={4} sm={4} className="navbar-links">
            <Nav>
              <Nav.Link href="#home">Home</Nav.Link>
              <Nav.Link href="#tee-time">Book a Tee Time</Nav.Link>
              <Nav.Link href="#memberships">Memberships</Nav.Link>
              <Nav.Link href="#contact">Contact</Nav.Link>
            </Nav>
          </Col>
        </Row>
      </Navbar.Collapse>
    </Navbar>
  );
}
