import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import clubhouse from "../assets/imgs/bg/clubhouse.png";
import youth from "../assets/imgs/bg/youth-golfer.png"
import golfer from "../assets/imgs/bg/golf-gang.png"

export default function About() {
  return (
    <div className="about-parent">
      <Container>
        <Row>
          <div className="title">
            Pinecone Values
          </div>
        </Row>
        <Row className="d-flex justify-content-center">
          <Col lg={5} md={6} sm={12}>
            <div className="overlay-container">
              <img src={clubhouse} className="background-image-history" />
              <div className="overlay-summary-title-history">History of Excellence</div>
              <div className="overlay-summary-writing-history">
                Established a century ago, our historic golf course embodies
                tradition and excellence.
                <br />
                Set amidst lush greenery, it boasts picturesque landscapes and
                challenging terrain.
              </div>
            </div>
          </Col>
          <Col lg={5} md={6} sm={12}>
          <div className="overlay-container">
              <img src={golfer} className="background-image-community-1" />
              <img src={youth} className="background-image-community-2" />
              <div className="overlay-summary-title-community">Community First</div>
              <div className="overlay-summary-writing-community">
              Community is integral at our golf course. 
              <br/>
              From friendly gatherings to tournaments, we foster connections among all players. 
              <br/>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
