import React, { useState } from "react";
import { Button } from "react-bootstrap";

export default function Btn({ child, type, onClick }) {
  const [hover, setHover] = useState(false);
  let style = {};

  if (type === "standard") {
    style = hover
      ? {
          color: "whitesmoke",
          fontWeight: "700",
          // backgroundColor: "rgb(28,73,50)",
          backgroundColor: "green",
          border: "2px solid rgb(28,73,50)",
          boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.2)",
        }
      : {
          color: "rgb(28,73,50)",
          fontWeight: "700",
          backgroundColor: "whitesmoke",
          border: "2px solid rgb(28,73,50)",
          boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.2)",
        };
  }

  if (type === "form") {
    style = hover
      ? {
          color: "whitesmoke",
          fontWeight: "700",
          backgroundColor: "green",
          border: "1px solid rgb(250,250,250)",
          padding: "1px 6px 1px 6px"
        }
      : {
          color: "rgb(28,73,50)",
          fontWeight: "700",
          backgroundColor: "white",
          border: "1px solid rgb(250,250,250)",
          padding: "1px 6px 1px 6px"
        };
  }

  return (
    <Button
      style={style}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onClick={onClick}
    >
      {child}
    </Button>
  );
}
