import React from "react";
import { Row, Col } from "react-bootstrap";
import driving from "../assets/imgs/bg/driving-range.png"
import room from "../assets/imgs/bg/golf-room.png"
import Btn from "./Btn";
import yes from "../assets/svgs/check-circle-fill-yes.svg";
import no from "../assets/svgs/check-circle-fill-no.svg";
import golfWin from "../assets/imgs/bg/golf-winner.png"

export default function Membership() {
  const details = [
    {
      title: "Gold Membership",
      desc: "For the dedicated weekend golfer seeking a touch of luxury",
      hours: "Unlimited access (off-peak hours)",
      clubhouse: "Access to locker rooms",
      tournament: "Invite to the annual Fairway Classic",
      coaching: "Personalized coaching sessions ",
      hoursSym: yes,
      clubhouseSym: no,
      tournamentSym: no,
      coachingSym: no,
      img: driving
    },
    {
      title: "Platinum Membership",
      desc: "Tailored for the avid golfer who demands excellence on the green",
      hours: "Unlimited access (operating hours)",
      clubhouse: "Access to private club",
      tournament: "Invite to the annual Fairway Classic",
      coaching: "Personalized coaching sessions ",
      hoursSym: yes,
      clubhouseSym: yes,
      tournamentSym: no,
      coachingSym: no,
      img:room
    },
    {
      title: "Diamond Membership",
      desc: "Designed for the elite golfer seeking unparalleled luxury and prestige",
      hours: "Unlimited access (priority times)",
      clubhouse: "Access to private club",
      tournament: "Invite to the annual Fairway Classic",
      coaching: "Personalized coaching sessions ",
      hoursSym: yes,
      clubhouseSym: yes,
      tournamentSym: yes,
      coachingSym: yes,
      img: golfWin
    },
  ];

  return (
    <>
      {details.map((item) => (
        <>
          <div className="membership card" style={{ width: "20rem", margin: "20px" }}>
            <img src={item.img} class="card-img-top" alt="..." />
            <div className="card-body">
              <h5 className="card-title">{item.title}</h5>
              <p className="card-text">{item.desc}</p>
            </div>
            <ul className="list-group list-group-flush">
              <div className="membership-group-item">
                <img src={item.hoursSym} />
                {item.hours}
              </div>
              <div className="membership-group-item">
                <img src={item.clubhouseSym} />
                {item.clubhouse}
              </div>
              <div className="membership-group-item">
                <img src={item.tournamentSym} />
                {item.tournament}
              </div>
              <div className="membership-group-item">
                <img src={item.coachingSym} />
                {item.coaching}
              </div>
            </ul>
            <Btn child="Contact Sales" type="standard" />
          </div>
        </>
      ))}
    </>
  );
}
