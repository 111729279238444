import React from "react";
import { Container, Row, Col,  Nav } from "react-bootstrap";
import Btn from "./Btn";

export default function Banner() {
  return (
    <div className="banner" id="home">
      <Container>
        <Row>
          <Col lg={12} md={12} sm={12}>
            <Nav.Link href="#tee-time">
              <Btn child="Book A Tee Time" type="standard" />
            </Nav.Link>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
